@import '../variables.scss';

.navigation {
  padding: 16px;

  .nav-wrapper {
    display: flex;
    justify-content: space-between;

    .logo {
      background-image: url(../assets/images/logo.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 97px;
      height: 40px;
    }

    .user-block {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: flex-end;

      .username-wrapper {
        display: flex;
        column-gap: 8px;
        align-items: center;

        .user-icon {
          width: 18px;
          height: 18px;
          background-image: url('../assets/images/user.png');
          background-size: contain;
        }
      }
    }
  }
}

@media (max-width: $dt) {
  .navigation {
    .nav-wrapper {
      .logo {
        width: 78px;
        height: 32px;
      }

      .user-block {
        .username-wrapper {
          .user-icon {
            width: 14px;
            height: 14px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
