.label-time {
  border-bottom: 1px solid gray;

  label {
    display: block;
  }

  select {
    padding: 8px 0;
    display: block;
    margin: 0 auto;
  }
}
