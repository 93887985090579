.order-form-4-5-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .row1,
  .row2,
  .row3,
  .row4 {
    display: flex;
    column-gap: 16px;
  }

  .row1 {
    & > :first-child {
      width: 80%;
    }
    & > :last-child {
      width: 20%;
    }
  }

  .row2,
  .row3 {
    & > :first-child {
      width: 60%;
    }
    & > :last-child {
      width: 40%;
    }
  }

  .row4 {
    & > :first-child {
      width: 40%;
    }
    & > :last-child {
      width: 60%;
    }
  }
}
