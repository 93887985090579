.modal-popup {
  .error-label {
    position: absolute;
    left: 0;
    top: 8px;
    color: red;
    display: block;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: opacity 300ms;

    &.show {
      opacity: 1;
    }
  }

  .signup-email-form {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
}
