.logo-text {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    background-image: url('../assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 280px;
    height: 120px;
  }

  .text {
    font-size: 32px;
    width: fit-content;
  }
}
