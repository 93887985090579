@import '../variables.scss';

.order-form-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 500ms ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 96px;

  &.no-transition {
    transition: none;
  }

  &.left {
    transform: translateX(-100vw);
  }

  &.right {
    transform: translateX(100vw);
  }

  .inner-wrapper {
    width: fit-content;
    box-shadow: 0 0 32px gray;
    border-radius: 16px;

    .header-wrapper {
      width: 100%;
      padding: 32px 32px 16px;
      border-bottom: 1px solid gray;
      text-align: center;
    }

    .form-wrapper {
      padding: 32px;
      width: 100%;

      .buttons-wrapper {
        padding-top: 32px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: $dt) {
  .order-form-template {
    margin-top: 40px;

    .inner-wrapper {
      box-shadow: 0 0 8px gray;

      .header-wrapper {
        padding: 16px 16px 8px;
      }

      .form-wrapper {
        padding: 16px;

        .buttons-wrapper {
          padding-top: 24px;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
