@keyframes showingup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  display: none;

  &.show {
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: showingup;
    animation-duration: 300ms;
  }

  .order-form-1-btn {
    position: relative;
  }
}
