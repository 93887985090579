@import '../variables.scss';

.order-form-7-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
  min-height: 120px;
  position: relative;

  .go-to-mobile {
    text-align: center;
  }

  label {
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    text-align: center;
    transition: opacity 300ms;

    &.invisible {
      opacity: 0;
    }

    &.error {
      color: red;
      opacity: 1;
    }
  }

  input {
    display: none;
  }

  .button-content-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .icon {
      width: 24px;
      height: 24px;
      background-image: url(../assets/images/upload.png);
      background-size: contain;
    }
  }
}

@media (max-width: $dt) {
  .order-form-7-wrapper {
    .go-to-mobile {
      display: none;
    }

    label {
      top: 0;
    }
  }
}
