.order-form-3-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .checkbox-date-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .checkboxes-block-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
