.label-input {
  label {
    display: block;

    &.error {
      color: red !important;
    }
  }

  &:focus-within {
    label {
      color: var(--primary-color);
    }
  }
}
