.label-date {
  border-bottom: 1px solid gray;

  label {
    display: block;

    &.error {
      color: red;
    }
  }

  .react-date-picker {
    margin: 0 auto;
    padding: 8px 0;
    display: block;
    width: fit-content;
    transition: opacity 500ms;

    &.react-date-picker--disabled {
      opacity: 0.5;
      color: unset;
      background-color: unset;
    }

    .react-date-picker__wrapper {
      border: none;

      .react-date-picker__inputGroup__year {
        min-width: 48px;
      }
    }

    .react-date-picker__inputGroup__month {
      min-width: 56px;
    }

    button {
      box-shadow: none;
    }
  }
}
