@import '../variables.scss';

.radio-label-wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  input {
    cursor: pointer;
  }

  label {
    font-size: 18px;
    color: black;
    cursor: pointer;
  }
}

@media (max-width: $dt) {
  .radio-label-wrapper {
    label {
      font-size: 16px;
    }
  }
}
