.pending-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 24px 0;

  h3 {
    text-decoration: underline;
  }
}
