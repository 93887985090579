.label-select {
  border-bottom: 1px solid gray;

  label {
    display: block;
  }

  select {
    margin: 8px 0;
    padding: 0;
    width: 100%;
  }
}
