@import '../variables.scss';

.order-form-1-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 500ms ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 96px;

  &.left {
    transform: translateX(-100vw);
  }

  &.right {
    transform: translateX(100vw);
  }

  &.no-margin-top {
    margin-top: 0;
  }

  .inner-wrapper {
    box-shadow: 0 0 32px gray;
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    overflow: hidden;

    .close-button-wrapper {
      position: absolute;
      top: 8px;
      right: 0px;
      opacity: 0.7;

      .close-icon {
        width: 16px;
        height: 16px;
        background-image: url('../assets/images/close.png');
        background-size: contain;
      }
    }

    .children-wrapper {
      position: relative;
      width: 100%;
      padding: 16px 0;
    }

    .under-btn-el-wrapper {
      padding-top: 24px;
    }
  }
}

@media (max-width: $dt) {
  .order-form-1-btn {
    margin-top: 40px;

    .inner-wrapper {
      padding: 16px;
      box-shadow: 0 0 8px gray;

      .h-padding {
        padding: 0 40px;
        text-align: center;
      }
    }
  }
}
