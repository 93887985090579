@import '../variables.scss';

.input-zip-country {
  border: 1px solid grey;
  border-radius: 4px;
  display: flex;
  column-gap: 32px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;

  &:focus-within {
    border: 1px solid var(--primary-color);

    label {
      color: var(--primary-color);
    }
  }

  label {
    position: absolute;
    top: -24px;
    padding: 0 4px;
    background-color: white;

    &.error {
      color: red;
    }
  }

  input {
    border: none;
    padding: 0;
    max-width: 190px;
  }

  .zip,
  .country {
    position: relative;
  }

  .country {
    label {
      right: 0;
    }
  }
}
