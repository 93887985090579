.label-autocomplete {
  display: flex;
  flex-direction: column;

  label {
    display: block;

    &.error {
      color: red !important;
    }
  }

  &:focus-within {
    label {
      color: var(--primary-color);
    }
  }

  .item {
    cursor: pointer;
    padding: 8px;

    &.highlighted {
      color: white;
      background-color: var(--primary-color);
    }
  }
}
