.progress-bar {
  position: relative;
  width: 100%;
  height: 16px;
  border-radius: 4px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 500ms;

  &.invisible {
    opacity: 0;
  }

  .done {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 4px;
  }

  .all {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    opacity: 0.5;
  }
}
