@import '../variables.scss';

.order-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  min-height: 1280px;

  form {
    position: relative;
  }
}

@media (max-width: $dt) {
  .order-wrapper {
    padding-top: 32px;
  }
}
