.centered-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .centered-inner {
    padding: 32px 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
  }
}
