.label-input-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .flag-input-wrapper {
    display: flex;
    column-gap: 16px;
    border-bottom: 1px solid gray;

    &:focus-within {
      border-bottom: 1px solid var(--primary-color);
    }

    input {
      border: none;
    }
  }
}
