@import '../variables.scss';

.icon-button {
  font-size: 22px;

  .icon-text-wrapper {
    display: flex;
    column-gap: 32px;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      background-size: contain;
    }

    .text-wrapper {
      flex-grow: 1;
    }
  }
}

@media (max-width: $dt) {
  .icon-button {
    font-size: 18px;
  }
}
