.intro-text {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 1000px;

  p {
    text-align: justify;
  }
}
